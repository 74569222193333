.menu-container {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  z-index: 99;
  height: var(--menu-height-2);
  box-sizing: border-box;
  border-bottom: 1px solid #222222;
  background: var(--ink-dark);
}

.menu-container .logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 2;
  transition: all ease 0.3s;
}

.menu-container .logo > img {
  width: 133px;
  padding-right: 45px;
}
.menu-container .logo > svg {
  width: 133px;
  padding-right: 45px;
}
.menu-container .logo svg:hover path {
  fill: var(--theme-primary);
  transition: all ease 0.3s;
}
.menu-container .logo svg path {
  transition: all ease 0.3s;
}

.menu-nav {
  display: none;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100%;
  list-style-type: none;
  list-style-position: inside;
  counter-reset: menuCounter;
}

.menu-nav > li {
  counter-increment: menuCounter;
  flex-grow: 1;
  text-align: center;
  text-transform: capitalize;
  max-width: 150px;
  opacity: 1;
  cursor: pointer;
  box-sizing: border-box;
  transition: opacity 0.15s ease-in-out;
}

.menu-container .menu-nav > li.selected {
  box-shadow: inset 0 -5px 0 -1px #222222;
  opacity: var(--theme-active-opacity);
  background: #222222;
}

.sub-menu-nav {
  display: none;
}

.menu-container .menu-nav > li.selected .sub-menu-nav {
  display: block;
}

/* .menu-container .menu-nav {
  margin-left: -235px;
} */

.menu-nav > li:hover {
  opacity: var(--theme-active-opacity);
}

.menu-nav > li::before {
  content: counter(menuCounter) ".";
  padding-right: 4px;
  font-size: 14px;
  font-weight: 600;
}

.menu-nav > li > h2 {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: var(--menu-height-2);
  font-weight: 600;
  font-size: 14px;
}

.mode-icon {
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
}

.logo-title {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.logo-title span {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 0.88;
}
.gallery-title {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: var(--menu-height-2);
  font-family: var(--font-w01-bold);
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 0;
}

@media (min-width: 992px) {
  .top-bar-sm {
    display: none;
  }

  .menu-container {
    justify-content: flex-start;
  }

  .menu-nav {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  #menu-right-side {
    display: none;
  }
  .top-bar-sm {
    height: 65px;
    background: var(--ink-dark);
    border-bottom: 1px solid var(--ink-charcoal-dark);
  }

  .top-bar-sm .logo {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5em;
    align-items: center;
    height: 100%;
    text-decoration: none;
  }

  .top-bar-sm .logo-title {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: var(--ink-light);
    font-weight: 700;
  }

  .top-bar-sm .logo-title span {
    color: var(--ink-greyish);
    font-size: 11px;
  }

  .menu-container {
    top: 66px;
    justify-content: flex-start;
    padding: 0px;
    height: 48px;
  }

  .menu-container .menu-nav {
    margin-left: unset;
    display: flex;
  }

  .menu-container .logo {
    display: none;
  }

  .menu-nav {
    display: flex;
    justify-content: center;
  }

  .menu-nav > li::before {
    font-size: 12px;
  }

  .menu-container .menu-nav > li > h2 {
    line-height: 48px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .top-bar-sm {
    height: 45px;
  }

  .top-bar-sm .logo {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5em;
    align-items: center;
    height: 100%;
  }

  .top-bar-sm .logo-title {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--ink-light);
  }

  .top-bar-sm .logo-title span {
    color: var(--ink-greyish);
    font-size: 9px;
  }

  .menu-container {
    top: 46px;
    justify-content: flex-start;
    padding: 0px;
    height: 36px;
  }

  .menu-container .menu-nav {
    margin-left: unset;
    display: flex;
  }

  .menu-container .logo {
    display: none;
  }

  .menu-nav {
    display: flex;
    justify-content: center;
  }

  .menu-container .menu-nav > li > h2 {
    line-height: 36px;
  }
  #menu-right-side {
    display: none;
  }
  .menu-nav > li {
    max-width: 100%;
  }
  .top-bar-sm img {
    height: 24px;
    padding-top: 6px;
  }
}
