.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  height: var(--footer-height);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 18px;
  font-weight: 400;
  padding: 0 0px;
  border-top: none;
  color: var(--theme-footer-text);
  background: transparent;
}

.footer > * {
  flex: 1;
}

.footer > *:first-child {
  display: flex;
  justify-content: flex-start;
}

.footer > *:nth-child(2) {
  display: flex;
  justify-content: center;
  padding: 0 12px;
}

/* .footer>*:last-child {
    display: flex;
    justify-content: flex-end;
} */

.footer-inner {
  display: flex;
  flex-direction: column;
  align-content: center;
  max-width: 360px;
}

.footer-nav {
  display: flex;
  flex-direction: row;
}

.footer-nav.footer-nav-2 {
  flex-direction: column;
}

.footer button {
  text-align: right;
  cursor: pointer;
  background: var(--ink-dark);
  color: white;
  font-family: var(--font-w01-bold);
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 24px;
  outline: none;
  border: none;
  border-radius: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 75px;
  padding: 16px 6px;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.footer button:focus,
.footer button:active {
  box-shadow: unset;
}

.footer button:disabled {
  cursor: auto;
  opacity: 1;
  background: var(--theme-surface-2);
}
.footer button.disablePrev {
  background: #1b1b1b;
}
.footer button > svg {
  min-width: 24px;
  min-height: 24px;
}
.footer-inner .total-block-btn {
  padding: 0;
}
.footer-inner h5.total-block {
  background: var(--theme-primary);
  text-align: center;
  padding: 17px;
  margin: 0;
  font-size: 18px;
  width: 100%;
}

.footer-inner small.total {
  margin: 0;
  font-family: var(--font-w01);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 2px;
  color: var(--ink-very-light-pink);
  display: block;
}

.footer button.footer-nav-2-button {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  background: var(--theme-primary);
  padding: 26px 32px;
}

.footer button.footer-nav-2-button.btn-2 {
  color: var(--ink-light);
  font-family: var(--font-w01);
  background: var(--ink-charcoal-dark);
  background: #000;
}

.footer .footer-inner-sm {
  display: none;
}

.footer .footer-inner-lg {
  display: flex;
}

.footer button.btn-getQuote {
  background: var(--theme-primary);
  color: white;
}

@media (min-width: 576px) {
  .footer {
    font-size: 24px;
  }

  .footer button {
    width: 100%;
    max-width: 300px;
    max-width: 360px;
  }

  .footer button > span {
    display: inline-block;
  }
  .footer .footer-nav-1 button {
    font-size: 13px;
    white-space: nowrap;
  }
  .footer .footer-nav-1 button > span {
    text-align: left;
    line-height: normal;
    padding: 0 1.5rem;
  }

  .footer button > span > small {
    display: block;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    font-family: var(--font-w01);
  }
}

@media (max-width: 1024px) {
  .footer-inner {
    width: 100%;
    max-width: 100%;
  }

  .footer .footer-inner-lg {
    display: none;
  }

  .footer .footer-inner-sm {
    display: flex;
  }

  .footer > *:nth-child(2) {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .footer-inner h5.total-block {
    width: 100%;
    line-height: 24px;
    padding: 16px 6px;
  }

  .footer-nav.footer-nav-2 {
    flex-direction: row;
    z-index: 3;
    margin-bottom: -2px;
  }

  .footer button {
    width: 100%;
    max-width: 100%;
    text-align: left;
  }

  .footer button > span > small {
    display: block;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    font-family: var(--font-w01);
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .footer {
    height: 68px;
  }
  #envBtns {
    display: none;
  }
  .footer button {
    font-size: 13px;
    font-weight: normal;
  }
  /* .footer button:disabled {
    background: #000;
  } */
  .footer button.disablePrev {
    background: #1b1b1b;
  }
  .footer-inner h5.total-block {
    font-size: 16px;
    line-height: normal;
    padding: 16px 20px;
  }
  .footer-nav {
    width: 100%;
  }
}
