iframe.api-iframe-model {
    position: absolute;
    border: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
