.settings {
  padding: 32px;
  box-sizing: border-box;
  text-align: center;
}

.settings-group {
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 0px;
}

.settings-group:last-child {
  margin-bottom: 45px;
}

.settings-group > h3 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 16px;
  padding-left: 25px;
}

.settings-group-disclaimer {
  font-size: 14px;
  line-height: 24px;
  opacity: var(--theme-medium-opacity);
  padding-left: 25px;
}

.settings-options {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.settings-options .settings-options-text {
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.settings-group-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-group-label > span:first-child {
  padding-right: 12px;
}

.settings-group-label > span:last-child {
  padding-left: 12px;
}

.settings-group-label > .price {
  font-size: 14px;
  text-transform: capitalize;
}

.settings-group-benefits > p {
  font-weight: 500;
}

.settings-group-benefits > ul {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.settings-group-benefits > ul > li {
  margin-bottom: 0.6em;
}

.menu-container-sub .menu-nav > li[data-sub-menu="Paint"].selected {
  .settings-group[data-sub-menu-options="Paint"] {
    display: none;
  }
}
.ReactModal__Overlay {
  background-color: rgba(45, 47, 55, 0.8) !important;
}
.requirement-title {
  font-family: var(--font-w01-bold);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: 2.8px;
  text-align: left;
  color: #fff4f4;
}
.requirement-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fff4f4;
  margin-bottom: 45px;
  margin-top: 22px;
}
.requirement-btn {
  text-align: right;
  cursor: pointer;
  background: transparent;
  color: white;
  font-family: var(--font-w01-bold);
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 24px;
  outline: none;
  border: solid 1px #fff;
  border-radius: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  box-sizing: border-box;
  transition: all 0.2s ease;
}
.requirement-btn-2 {
  color: var(--theme-primary);
  border: solid 1px var(--theme-primary);
}
.requirement-form {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .settings {
    flex: 1;
    overflow-y: auto;
    min-width: 360px;
    max-width: 360px;
    padding: 48px 0;
    text-align: left;
    padding-top: 0;
    padding-bottom: 170px;
  }

  .settings-group-label {
    justify-content: space-between;
  }

  .settings-options {
    justify-content: flex-start;
  }

  .settings-options .settings-options-text {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .settings {
    padding: 0px 0;
    box-sizing: border-box;
    text-align: center;
  }

  .text-option {
    padding: 32px 20px;
  }

  .settings-group {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 0px !important;
  }

  .settings-options {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    justify-content: flex-start;
  }

  .text-option {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  #list-item-ink {
    display: flex;
    height: 200px;
    /* justify-content: space-between; */
    overflow-x: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    margin-bottom: var(--footer-height);
  }
  #list-item-ink .option {
    flex-basis: 25%;
    flex-shrink: 0;
    height: 100%;
  }
  .ReactModal__Content {
    height: auto !important;
    width: auto !important;
    inset: 0 !important;
    top: calc(100% - var(--footer-height) - (200px)) !important;
  }
  .requirement-text {
    margin-top: 12px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  #list-item-ink {
    height: 200px;
    margin-bottom: 68px;
  }
  #list-item-ink .option {
    flex-basis: 33.3%;
    flex-shrink: 0;
    height: 100%;
  }
}
