.menu-container-sub {
  position: fixed;
  top: 68px;
  width: 100%;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  z-index: 99;
  height: var(--menu-height);
  box-sizing: border-box;
  border-bottom: 1px solid var(--theme-separator);
  background: var(--ink-charcoal-dark);
}

.menu-container-sub .logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
}

.menu-nav {
  display: none;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100%;
  list-style-type: none;
  list-style-position: inside;
  counter-reset: menuCounter;
}

.menu-container-sub .menu-nav > li {
  counter-increment: menuCounter;
  flex-grow: 1;
  text-align: center;
  text-transform: capitalize;
  opacity: 1;
  max-width: 120px;
  margin: 0px 20px;
  cursor: pointer;
  box-sizing: border-box;
  transition: opacity 0.15s ease-in-out;
}

.menu-container-sub .menu-nav > li.selected {
  box-shadow: inset 0 -5px 0 -1px var(--theme-primary);
  opacity: var(--theme-active-opacity);
  background: var(--ink-charcoal-dark);
  color: var(--theme-primary);
}

.menu-container-sub .menu-nav > li:hover {
  opacity: var(--theme-active-opacity);
}

.menu-container-sub .menu-nav > li::before {
  content: none;
  padding-right: 4px;
  font-size: 14px;
  font-weight: 500;
}

.menu-container-sub .menu-nav > li > h2 {
  display: inline-block;
  margin: 0;
  padding: 0px 0;
  line-height: var(--menu-height);
  font-weight: 500;
  font-size: 13px;
  color: var(--ink-light);
}

.menu-container-sub .menu-nav > li.selected > h2 {
  color: var(--theme-primary);
  font-weight: 700;
}

.mode-icon {
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
}

@media (min-width: 992px) {
  .menu-container-sub {
    justify-content: flex-start;
  }

  .menu-nav {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .menu-container-sub {
    top: 114px;
    height: 36px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .menu-container-sub .menu-nav {
    display: flex;
  }

  .menu-container-sub .menu-nav li {
    display: inline-block;
    text-align: center;
    padding: 0 10px;
  }

  .menu-container-sub .menu-nav > li > h2 {
    line-height: 36px;
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .menu-container-sub {
    top: 82px;
    height: 32px;
  }

  .menu-container-sub .menu-nav > li > h2 {
    line-height: 32px;
  }
}
