@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  border: 16px solid #fff;
  border-top: 16px solid #4fc3f7;
}

.preloader {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.preloader-section {
  justify-content: center;
  align-items: center;
  background: #000;
  display: flex;
  height: 100vh;
  padding: 0;
  margin: 0;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(45, 47, 55, 0.8);
  position: relative;
  z-index: 9999999999;
  flex-direction: column;
}

.progress-div {
  justify-content: center;
  align-items: center;
  background: #000;
  display: var(--bg-loader);
  height: 100vh;
  padding: 0;
  margin: 0;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(45, 47, 55, 0.8);
  position: relative;
  z-index: 9999999999;
  flex-direction: column;
  opacity: 1;
}
.loaderImage {
  margin-bottom: 1rem;
  width: 180px;
}
.progress-div .progress-bar {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 1px 1px;
  display: flex;
  height: 8px;
  width: 180px;
}

.progress-div .progress-value {
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #fff;
  height: 8px;
  width: var(--bg-size);
}
.loaded .progress-div {
  display: var(--bg-loader);
}
