.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0 0px 0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.specs {
  max-width: 650px;
  width: 100%;
  margin: 0;
  padding: 0 32px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
}

.specs > li:not(:last-child) {
  border-right: 1px solid var(--theme-separator);
}

.specs > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}

.specs > li:first-child {
  padding-left: 0;
}

.specs > li:last-child {
  padding-right: 0;
}

.specs > li > span.specs-value {
  font-size: 1.5rem;
}

.specs > li > span.specs-label {
  font-size: 1rem;
  padding-top: 8px;
}

@media (min-width: 576px) {
  .specs > li {
    padding: 0 32px;
  }

  .specs > li > span.specs-value {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .preview {
    flex: 4;
    border-right: 0px solid var(--theme-separator);
  }
}

@media (max-width: 1024px) {
  .preview {
    padding: 0;
    height: calc(100% - var(--footer-height) - (200px));
  }

  .slideshow {
    height: 100%;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.slideshow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  user-select: none;
}

.slideshow .arrow {
  display: none;
  position: absolute;
  padding: 1.5rem;

  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  cursor: pointer;
  opacity: 0.45;
  transition: all 0.2s ease-in-out;
}

.slideshow .arrow:hover {
  opacity: 1;
  box-shadow: 2px -2px 0 2px var(--theme-primary) inset;
  -webkit-box-shadow: 4px -4px var(--theme-primary) inset;
}

.slideshow .arrow-next {
  transform: translateY(-50%) rotate(225deg);
  top: 50%;
  right: 16px;
}

.slideshow .arrow-prev {
  transform: translateY(-50%) rotate(45deg);
  top: 50%;
  left: 16px;
}

.slideshow-slide {
  display: none;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

.slideshow-slide.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-slide.active > img {
  /* max-width: 100%; */
  max-height: 100%;
  min-width: 100%;
}

.slideshow-slide > img.scale {
  transform: scale(1.4);
}

@media (min-width: 576px) {
  .slideshow .arrow {
    display: block;
  }
}

@media (min-width: 992px) {
  .slideshow {
    height: 100%;
  }
}
