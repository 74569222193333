.summary {
  padding: 32px;
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 200px;
}

.summary > h1 {
  padding: 0;
  margin: 0;
}
.summary > h2 {
  text-transform: uppercase;
}
.summary-edd {
  text-decoration: underline;
}

.summary-content {
  text-align: left;
  margin-top: 32px;
}

.summary-content > p {
  font-weight: 500;
  margin: 0;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}

.summary-content > p:first-child {
  font-size: 16px;
  font-weight: 600;
}

.summary-content > p:last-child {
  font-size: 14px;
  border-top: 1px solid var(--theme-separator);
  font-weight: 600;
}

.summary-content > ul {
  margin: 16px 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  margin-top: 0;
}

.summary-content > ul > li {
  padding: 8px 0;
}

.summary-content > ul > li > h5 {
  display: block;
  font-size: 14px;
  color: #4499b1;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  padding-top: 12px;
  border-top: 1px solid var(--theme-separator);
}

.summary-content > ul > li > .summary-content-row {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: var(--ink-very-light-pink);
}

.summary-content-row-price {
  color: #ffffff;
}

.summary-content > ul > li > span:last-child {
  padding-left: 12px;
  text-transform: capitalize;
  opacity: var(--theme-medium-opacity);
}

.summary-form {
  margin-left: -48px;
  margin-right: -48px;
  background: #222222;
  padding: 48px;
  margin-top: 45px;
  padding-bottom: 80px;
  position: relative;
}

.summary-form h6 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-align: left;
  margin-top: 0;
  margin-bottom: 45px;
}

.summary-form label {
  text-align: left;
}
input:focus {
  outline: none;
}
/* remove the input box styling */

/* once you click in the input the input width box animates */

/* the text floats up and turns white */

#contact-footer .input-field {
  position: relative;
}

#contact-footer label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #4d4d4d;
  transition: 0.2s all;
  cursor: text;
  font-weight: 500;
  font-family: var(--font-w01-bold);
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 3.3px;
}

#contact-footer input {
  width: 100%;
  border: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #4d4d4d;
  box-shadow: none;
  height: auto;
  margin: 0 0 30px;
  background: transparent;
  z-index: 99;
  position: relative;
  color: #ffffff !important;
  font-weight: 200;
  border-radius: 0;
  font-family: var(--font-w01-bold);
}

#contact-footer input:invalid,
#contact-footer textarea:invalid {
  outline: 0;
  color: var(--ink-greyish);
  border-color: #4d4d4d;
  background: transparent;
}

#contact-footer input:focus {
  border-color: #4499b1;
  background: transparent;
}

#contact-footer input:focus ~ label,
#contact-footer #email:focus ~ label {
  font-size: 10px;
  top: -24px;
  color: #4499b1;
}

#contact-footer label.error,
#contact-footer input:focus ~ label.error,
#contact-footer textarea:focus ~ label.error {
  position: absolute;
  top: auto;
  bottom: 25px;
  color: #4499b1;
  font-size: 10px;
  font-weight: 400;
}
.missing-fields {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  color: #4499b1;
  margin-bottom: 0;
  text-align: left;
}

.text-primary {
  color: #4499b1;
}

.success-msg h6 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 3.6px;
  text-align: left;
}
.success-msg p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
}
.submit-btn-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 360px;
  z-index: 99;
  height: 68px;
  box-sizing: border-box;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  padding: 0 0px;
  border-top: none;
  background: transparent;
  z-index: 999;
  align-content: center;
}
.submit-btn {
  background: var(--theme-primary);
  color: white;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  font-family: var(--font-w01-bold);
  font-weight: 600;
}
@media (min-width: 992px) {
  .summary {
    flex: 1;
    overflow-y: auto;
    min-width: 360px;
    max-width: 360px;
    padding: 48px;
    padding-bottom: 270px;
  }
}
@media (max-width: 1024px) {
  .summary-content > ul > li > .summary-content-row {
    font-size: 13px;
  }
  .submit-btn-footer {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .summary {
    padding-bottom: calc(100% - var(--footer-height));
  }
}
