.app {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
}

.app-content {
    width: 100%;
    /* height: calc(100% - var(--menu-height) - var(--footer-height)); */
    height: calc(100% - var(--menu-height));
    margin-top: calc(var(--menu-height) + var(--menu-height-2));
    /* margin-top: 140px; */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.slideshow-slide.active>iframe {
    max-height: 100%;
    min-width: 100%;
}

@media(min-width: 992px) {
    .app-content {
        flex-direction: row;
        justify-content: center;
        overflow-y: hidden;
    }
}

@media(max-width: 1024px) {
    .app-content {
        height: calc(100% - 151px);
        margin-top: 84px;
    }
}

@media(max-width: 767px) {
    .app-content {
        height: calc(100% - 114px);
        margin-top: 68px;
    }
}
