:root {
  --bg-size: 0%;
  --bg-loader: flex;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  color: var(--theme-text);
  background: var(--theme-background);
  transition: all 0.25s ease-in-out;

  --theme-background: #ffffff;
  --theme-footer-background: #333;
  --theme-surface: transparent;
  --theme-text: #151618;
  --theme-footer-text: #ffffff;
  --theme-primary: #4499b1;
  --theme-separator: #dfdfdf;
  --theme-footer-separator: #333;
  --theme-border: #ddd;
  --theme-surface-border: #ddd;

  --theme-active-opacity: 1;
  --theme-medium-opacity: 0.6;
  --theme-disabled-opacity: 0.38;

  --theme-drop-shadow: none;
  --menu-height: 50px;
  --menu-height-2: 68px;
  --footer-height: 125px;

  --ink-light: #ffffff;
  --ink-dark: #000000;
  --ink-dark-2: #22242a;
  --ink-charcoal-dark: #222222;
  --ink-charcoal-light: #404350;
  --ink-greyish: #b4b4b4;
  --ink-very-light-pink: #fff4f4;
  --ink-steel: #7a7f94;

  --font-w01: "Poppins", sans-serif;
  --font-w01-light: "Poppins", sans-serif;
  --font-w01-bold: "Poppins", sans-serif;

  --font-w10: "Poppins", sans-serif;
  --font-w10-light: "Poppins", sans-serif;

  --font-nissan: "Poppins", sans-serif;
  --theme-loader: 0%;
}

body.dark-mode {
  --theme-background: #222222;
  --theme-footer-background: #2a2b30;
  --theme-surface: #222222;
  --theme-surface-2: #474747;
  --theme-text: #ffffff;
  --theme-footer-text: #ffffff;
  --theme-primary: #4499b1;
  --theme-separator: rgba(244, 245, 246, 0.12);
  --theme-footer-separator: rgba(244, 245, 246, 0.12);
  --theme-border: #151618;
  --theme-surface-border: rgba(244, 245, 246, 0.12);
  --theme-drop-shadow: drop-shadow(0 0 0.75rem var(--theme-primary));
}
